export {}

window.onerror = function (e: Event) {
    console.log(e);

    const errorContainer = document.getElementById("error-container");
    const root = document.getElementById("root");    
    const errorImage = document.createElement("img");
    const errorMessage = document.createElement("h3");
    const refreshButton = document.createElement("button");

    errorMessage.innerText = 'An error has occurred, please refresh the page or contact support by clicking the Request Support button above.';
    errorMessage.style.textAlign = 'center';
    errorMessage.style.color = '#0078d4';

    errorImage.src = "/ui/public/PM-WhiteFlag-error.svg"
    errorImage.alt = "PoliteMail Error Image"
    errorImage.classList.add("error-image")

    refreshButton.innerHTML = "Refresh page";
    refreshButton.className = "refresh-button";
    refreshButton.onclick = () => window.location.reload();
    
    errorContainer.appendChild(errorImage)
    errorContainer.appendChild(errorMessage);
    errorContainer.appendChild(refreshButton);
    errorContainer.style.display = 'flex';
    
    root.style.display = 'none';
    
    return true; // prevents browser error messages  
};
